import React, {useState} from "react"
import {
    ContactFormElement,
    ContactFormElementInput, ContactFormElementSubmit,
    ContactFormElementTextarea,
    ContactFormRow,
    ContactFormWrapper
} from "./ContactElements";

const ContactForm = () => {
    const GOOGLE_FORM_URL = "https://docs.google.com/forms/d/e/1FAIpQLSdEPVTESOGJhpcsgGlMwlc_moCGl19PbyWykU2CtEn4wd_PHg/formResponse";

    const formValuesInitState = {
        name: '',
        email: '',
        message: '',
    };

    const [formValues, setFormValues] = useState(formValuesInitState);
    const [hasSendEmail, setHasSendEmail] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formValues.email === '' || formValues.name === '') {
            return;
        }

        const formData = new FormData();
        formData.append("entry.1803340898", formValues.name);  // Replace with your actual form field IDs
        formData.append("entry.662671763", formValues.email);
        formData.append("entry.1644283035", formValues.message);

        await fetch(GOOGLE_FORM_URL, {
            method: "POST",
            body: formData,
            mode: "no-cors"
        });

        setFormValues(formValuesInitState);
        setHasSendEmail(true);
    };

    const handleChange = (e) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    };

    const submitButtonText = hasSendEmail ? 'We will contact you soon!' : 'Submit';

    return (
        <>
        <ContactFormWrapper>
            <form id="contact-form" onSubmit={handleSubmit} method="POST">
                
                    <ContactFormRow>
                        <ContactFormElement className="form-group">
                            <label htmlFor="name">Name</label>
                            <ContactFormElementInput type="text" className="form-control" name="name" value={formValues.name}  onChange={handleChange}/>
                        </ContactFormElement>
                    <ContactFormRow>
                        <ContactFormElement className="form-group">
                            <label htmlFor="email">Email address</label>
                            <ContactFormElementInput type="email" className="form-control" aria-describedby="emailHelp" name="email" value={formValues.email}  onChange={handleChange}/>
                        </ContactFormElement>
                    </ContactFormRow>
                    </ContactFormRow>
                    <ContactFormRow>
                        <ContactFormElement className="form-group">
                            <label htmlFor="message">Message</label>
                            <ContactFormElementTextarea className="form-control" rows="5" name="message" placeholder='Your message...' value={formValues.message}  onChange={handleChange}/>
                        </ContactFormElement>
                    </ContactFormRow>
                    <ContactFormElementSubmit type="submit">{`${submitButtonText}`}</ContactFormElementSubmit>
            </form>
            </ContactFormWrapper>
        </>
    )
}

export default ContactForm;
