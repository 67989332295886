import React from "react";
import {
    LandingPageBg,
    LandingPageContainer,
    LandingPageContent,
    LandingPageImage, 
    LandingPageText, 
    LandingPageSubTitle, 
    LandingPageSmallerText,
    LandingPageSecondContainer,
    LandingPageSecondContent,
    LandingPageSubText
} from "./LandingPageElements";
import logo from '../../images/ps_wallpaper.jpg';
import secondImage from '../../images/landing_page_second_image.jpg';
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import './LandingPage.css';


const LandingPage = () => {
    const navigate = useNavigate();

    return (
        <>
            <LandingPageContainer>
                <LandingPageBg>
                    <LandingPageImage src={logo} className="App-logo" alt="logo" />
                </LandingPageBg>
                <LandingPageContent>
                    <LandingPageSubTitle>
                        {/* Add a title if needed */}
                        <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        Your brand deserves a story that captivates. 
                        <br />We bring it to life.
                    </LandingPageSubTitle>
                    
                    <LandingPageSmallerText>

                    </LandingPageSmallerText>
                </LandingPageContent>
            </LandingPageContainer>
            
            <LandingPageSecondContent>
                <LandingPageSubText>
                We create promotional and corporate videos that capture attention, build brand identity, and drive sales. 
                    <br />
                    <br />
                    <br />
                    <br />
                    We help businesses, marketing agencies, and artists stand out with dynamic productions that combine strategy, emotion and delivering value tailored to your needs.
                    <br />
                </LandingPageSubText>
            </LandingPageSecondContent>
            <LandingPageSecondContainer>
                <LandingPageImage src={secondImage} className="second-image" alt="logo" />
            </LandingPageSecondContainer>
            <LandingPageSecondContent>
                <LandingPageText>
                    Want drone shots? Professional makeup or a photoshoot? 
                    <br />
                    Maybe you need an actor, model, or extra camera operator? 
                    <br />
                    <br />
                    We are not here to limit you!
                    <br />
                    <br />
                    Just tell us what you need, and we will handle the rest!
                    <br/>
                    <br />
                    <Button className="rounded-button" onClick={() => navigate("/contact")}>
                        CONTACT US
                    </Button>
                    <br />
                    <br />
                    We will customize everything to fit your needs.
                    <br />
                    <br />
                </LandingPageText>
            </LandingPageSecondContent>
        </>
    );
}

export default LandingPage;
