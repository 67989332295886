import React from "react";
import {FooterItem, FooterWrapper} from "./FooterElements";
import {NavLinks, NavRouterLinks} from "../navbar/NavBarElements";
import {FaFacebook, FaInstagram} from "react-icons/fa";

const Footer = () => {
    return (
        <>
            <FooterWrapper>
                <FooterItem>
                    <NavRouterLinks to='/work'>VIDEO</NavRouterLinks>
                </FooterItem>
                <FooterItem>
                    <NavRouterLinks to='/contact'>CONTACT</NavRouterLinks>
                </FooterItem>
                <FooterItem>
                    <NavLinks href={'https://www.instagram.com/progress.studio.uk'} target="_blank" rel="noopener noreferrer">
                        <FaInstagram />
                    </NavLinks>
                </FooterItem>
                <FooterItem>
                    <NavLinks href={'https://www.facebook.com/progress.studio.uk'} target="_blank" rel="noopener noreferrer">
                        <FaFacebook />
                    </NavLinks>
                </FooterItem>
            </FooterWrapper>
        </>
    )
}


export default Footer;
