import React, {useState} from "react";
import {WorkContainer, WorkVideo, WorkVideoTitle, WorkVideoSubtitle, WorkVideoTitleMobile} from "./WorkElements";

const Work = () => {
    const [width] = useState(window.innerWidth);
    const isMobile = width <= 768;
    const videoWidth = isMobile ? '360px' : '1024px';
    const videoHeight = isMobile ? '640px' : '768px';

    return (
        <>
            <WorkContainer>
                <WorkVideoTitle>C O R P O R A T E + P R O M O T I O N A L</WorkVideoTitle>
                <WorkVideoTitleMobile style={{marginTop: '40px'}}>C O R P O R A T E</WorkVideoTitleMobile>
                <WorkVideoTitleMobile>P R O M O T I O N A L</WorkVideoTitleMobile>
                <WorkVideoSubtitle>Corporate & Promo videos for companies looking to attract customers and investors</WorkVideoSubtitle>
                {/*GFF Dance Studio*/}
                <WorkVideo width={videoWidth} height={videoHeight} controls url={"https://youtu.be/mUDFGwS3MpM"} />
                <br />
                <br />
                {/*Iwona beauty clinic*/}
                <WorkVideo width={videoWidth} height={videoHeight} controls url={"https://www.youtube.com/watch?v=iY1sSU2GSb8"} />

                <WorkVideoTitle>E V E N T</WorkVideoTitle>
                <WorkVideoTitleMobile style={{marginTop: '40px'}}>E V E N T</WorkVideoTitleMobile>
                <WorkVideoSubtitle>Event videos that encapsulate the atmosphere, energy, and highlights of your event, creating lasting impressions.</WorkVideoSubtitle>
                {/*Womens Club*/}
                <WorkVideo width={videoWidth} height={videoHeight} controls url={"https://youtu.be/jgutK7HLae4"} />
                <br />
                <br />
                {/*GFF 10-lecie*/}
                <WorkVideo width={videoWidth} height={videoHeight} controls url={"https://www.youtube.com/watch?v=DCCsnDZLrsk"} />
                <br />
                <br />
                {/*Bristol Purge*/}
                <WorkVideo width={videoWidth} height={videoHeight} controls url={"https://www.youtube.com/watch?v=UiqblNPVmwU"} />
                <br />
                <br />
                {/*Bieszczady*/}
                <WorkVideo width={videoWidth} height={videoHeight} controls url={"https://www.youtube.com/watch?v=YmtxVLDUkdM"} />
                
                <WorkVideoTitle>M U S I C</WorkVideoTitle>
                <WorkVideoTitleMobile style={{marginTop: '40px'}}>M U S I C</WorkVideoTitleMobile>
                <WorkVideoSubtitle>Music videos that bring the value you care about.</WorkVideoSubtitle>
                {/*Robson */}
                <WorkVideo width={videoWidth} height={videoHeight} controls url={"https://www.youtube.com/watch?v=I1yY5zcnsQE"} />
                <br />
                <br />
                {/*Dont Koks */}
                <WorkVideo width={videoWidth} height={videoHeight} controls url={"https://www.youtube.com/watch?v=8y1St65Dm8Q"} />
                <br />
                <br />
                {/*Pirate Studio */}
                <WorkVideo width={videoWidth} height={videoHeight} controls url={"https://www.youtube.com/watch?v=8zdjjFczqlc"} />

                <WorkVideoTitle>W E D D I N G</WorkVideoTitle>
                <WorkVideoTitleMobile style={{marginTop: '40px'}}>W E D D I N G</WorkVideoTitleMobile>
                <WorkVideoSubtitle>Wedding films that will properly touch your emotions, taking you back to that unforgettable day.</WorkVideoSubtitle>
                {/*Beth i Phillip*/}
                <WorkVideo width={videoWidth} height={videoHeight} controls url={"https://www.youtube.com/watch?v=a--J1kZxwC4"} />
                <br />
                <br />
                {/*Dorota*/}
                <WorkVideo width={videoWidth} height={videoHeight} controls url={"https://youtu.be/2vrFISkZoMc "} />
                <br />
                <br />
                {/*Kuba i Kasia*/}
                <WorkVideo width={videoWidth} height={videoHeight} controls url={"https://www.youtube.com/watch?v=Uec9lYG_bnU"} />

            </WorkContainer>
        </>
    )
}

export default Work;
